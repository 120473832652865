var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = conditionalTag;
exports.msoConditionalTag = msoConditionalTag;
exports.endNegationConditionalTag = exports.startMsoNegationConditionalTag = exports.startNegationConditionalTag = exports.endConditionalTag = exports.startMsoConditionalTag = exports.startConditionalTag = void 0;
const startConditionalTag = "<!--[if mso | IE]>";
exports.startConditionalTag = startConditionalTag;
const startMsoConditionalTag = "<!--[if mso]>";
exports.startMsoConditionalTag = startMsoConditionalTag;
const endConditionalTag = "<![endif]-->";
exports.endConditionalTag = endConditionalTag;
const startNegationConditionalTag = "<!--[if !mso | IE]><!-->";
exports.startNegationConditionalTag = startNegationConditionalTag;
const startMsoNegationConditionalTag = "<!--[if !mso><!-->";
exports.startMsoNegationConditionalTag = startMsoNegationConditionalTag;
const endNegationConditionalTag = "<!--<![endif]-->";
exports.endNegationConditionalTag = endNegationConditionalTag;
function conditionalTag(content, negation = false) {
  return `
    ${negation ? startNegationConditionalTag : startConditionalTag}
    ${content}
    ${negation ? endNegationConditionalTag : endConditionalTag}
  `;
}
function msoConditionalTag(content, negation = false) {
  return `
    ${negation ? startMsoNegationConditionalTag : startMsoConditionalTag}
    ${content}
    ${negation ? endNegationConditionalTag : endConditionalTag}
  `;
}
export default exports;
export const __esModule = exports.__esModule;
const _msoConditionalTag = exports.msoConditionalTag,
  _endNegationConditionalTag = exports.endNegationConditionalTag,
  _startMsoNegationConditionalTag = exports.startMsoNegationConditionalTag,
  _startNegationConditionalTag = exports.startNegationConditionalTag,
  _endConditionalTag = exports.endConditionalTag,
  _startMsoConditionalTag = exports.startMsoConditionalTag,
  _startConditionalTag = exports.startConditionalTag;
export { _msoConditionalTag as msoConditionalTag, _endNegationConditionalTag as endNegationConditionalTag, _startMsoNegationConditionalTag as startMsoNegationConditionalTag, _startNegationConditionalTag as startNegationConditionalTag, _endConditionalTag as endConditionalTag, _startMsoConditionalTag as startMsoConditionalTag, _startConditionalTag as startConditionalTag };